import React from 'react';

const TabButtons = ({ buttons, changeTab, activeTab, setCategory, setCurrentPage }) => {
  return (
    <div className="tab-buttons">
      {buttons.map((button) => {
        return (
          <button
            key={`button-${button}`}
            className={button === activeTab ? 'active' : ''}
            onClick={() => {
              changeTab(button);
              setCategory(button);
              setCurrentPage(1);
            }}
          >
            {button}
          </button>
        );
      })}
    </div>
  );
};

export default TabButtons;
