import React from 'react';
import Blog from '../../components/Shared/AllBlogPosts/Blog';
import NMDSILayout from '../../components/nmdsi/NMDSILayout';
import { graphql } from 'gatsby';

const blogDetail = ({ data }) => {
  return (
    <NMDSILayout>
      <div className="mx-auto max-w-screen-2xl">
        <Blog data={data} />
      </div>
    </NMDSILayout>
  );
};

export default blogDetail;

export const postQuery = graphql`
  query {
    allStrapiPosts(
      sort: { order: ASC, fields: published_at }
      filter: { websites: { elemMatch: { name: { eq: "NMDSI" } } } }
    ) {
      edges {
        node {
          date
          slug
          published_at
          title
          excerpt
          image {
            url
          }
          coverImage {
            url
          }
        }
      }
    }
    latestPosts: allStrapiPosts(
      sort: { order: DESC, fields: date }
      filter: { websites: { elemMatch: { name: { eq: "NMDSI" } } } }
      limit: 4
    ) {
      edges {
        node {
          slug
          published_at
          date
          image {
            url
          }
          title
        }
      }
    }
    allStrapiCategories(
      filter: { websites: { elemMatch: { name: { eq: "NMDSI" } } } }
      sort: { fields: posts___date, order: DESC }
    ) {
      nodes {
        name
        posts {
          slug
          published_at
          title
          excerpt
          date
          image {
            url
          }
        }
      }
    }
  }
`;
