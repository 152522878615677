import React, { useState } from 'react';
import { Link } from 'gatsby';
import { AiOutlineRight } from '@react-icons/all-files/ai/AiOutlineRight';
import EventPagination from '../../Pagination/EventPagination';
import Tabs from './Tab/Tabs';
import Tab from './Tab/Tab';
import BackToTop from '../BackToTop';

const Blog = ({ data }) => {
  const categories = data.allStrapiCategories.nodes;
  const [category, setCategory] = useState('All Articles');
  const [posts] = useState(data.allStrapiPosts.edges);
  const [currentPage, setCurrentPage] = useState(1);
  const dataLimit = 4;
  const numPages = Math.ceil(posts.length / dataLimit);

  const pages = [];

  categories.forEach((item) => {
    pages.push({
      category: item.name,
      pageNumber: 1,
      posts: item.posts,
    });
  });

  const getPaginatedData = (items, page) => {
    const startIndex = page * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return items.slice(startIndex, endIndex);
  };

  const convertDate = (pubdate) => {
    let newDate = new Date(pubdate);
    return newDate.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  return (
    <div className="w-full py-10 md:py-20">
      <div className="mx-auto max-w-7xl">
        <div className="">
          <div className="px-5 py-0 sm:py-10 md:px-10">
            <h1 className="mb-3 text-4xl font-bold uppercase text-blue md:text-6xl ">Blog: {category}</h1>
            <div className="flex mb-8">
              <div className="flex">
                <h3 className="text-base text-gray-500">Home</h3>
                <span className="flex self-center">
                  <AiOutlineRight className="mx-2 mt-1 text-sm text-grey" />
                </span>
              </div>
              <div className="flex">
                <h3 className="text-base text-gray-500">Blog</h3>
                <span className="flex self-center">
                  <AiOutlineRight className="mx-2 mt-1 text-sm text-grey" />
                </span>
              </div>
              <div className="flex">
                <h3 className="text-base text-blue">{category}</h3>
              </div>
            </div>
            <Tabs setCategory={setCategory} setCurrentPage={setCurrentPage}>
              <Tab label="All Articles">
                {getPaginatedData(
                  posts.sort((a, b) => new Date(b.node.date) - new Date(a.node.date)),
                  currentPage,
                ).map((item, i) => (
                  <div key={i} className="flex flex-col-reverse justify-between w-full mb-20 sm:flex-row">
                    <div className="flex flex-col content-center max-w-4xl " key={item.node.slug}>
                      <h2 className="mb-4">
                        <Link to={`/blog/${item.node.slug}`} className="text-4xl uppercase text-blue md:text-5xl">
                          {item.node.title}
                        </Link>
                      </h2>
                      <p className="max-w-md pb-3 text-lg md:max-w-xl xl:max-w-4xl text-brown">{item.node.excerpt}</p>
                      <p className="flex text-xs text-brown">{`${convertDate(item.node.date)}`}</p>
                    </div>
                    {item.node?.image?.[0]?.url && (
                      <div
                        className="w-full mb-5 bg-center bg-cover sm:ml-5 sm:mb-0 h-96 sm:w-56 sm:h-56"
                        style={{ backgroundImage: `url(${item.node.image[0].url})` }}
                      />
                    )}
                  </div>
                ))}
                <div className="flex justify-center w-full">
                  <EventPagination
                    currentPage={currentPage}
                    pageLimit={5}
                    setCurrentPage={setCurrentPage}
                    numPages={numPages}
                  />
                </div>
              </Tab>
              {pages.map((item, i) => {
                return (
                  <Tab label={item.category} key={i}>
                    {getPaginatedData(
                      item.posts.sort((a, b) => new Date(b.date) - new Date(a.date)),
                      currentPage,
                    ).map((data, j) => {
                      return (
                        <div key={j} className="flex flex-col-reverse justify-between w-full mb-20 sm:flex-row">
                          <div className="flex flex-col content-center max-w-4xl " key={data.slug}>
                            <h2 className="mb-4">
                              <Link to={`/blog/${data.slug}`} className="text-4xl uppercase text-blue md:text-5xl">
                                {data.title}
                              </Link>
                            </h2>
                            <p className="max-w-4xl pb-3 text-lg text-brown">{data.excerpt}</p>
                            <p className="flex text-xs text-brown">{`${convertDate(data.date)}`}</p>
                          </div>
                          {data?.image[0]?.url && (
                            <div
                              className="w-full mb-5 bg-center bg-cover sm:mb-0 h-96 sm:w-56 sm:h-56"
                              style={{ backgroundImage: `url(${data.image[0].url})` }}
                            />
                          )}
                        </div>
                      );
                    })}
                    {Math.ceil(item.posts.length / 4) > 1 && (
                      <div className="flex justify-center w-full">
                        <EventPagination
                          currentPage={currentPage}
                          pageLimit={Math.ceil(item.posts.length / 4) > 5 ? 5 : Math.ceil(item.posts.length / 4)}
                          setCurrentPage={setCurrentPage}
                          numPages={Math.ceil(item.posts.length / 4)}
                        />
                      </div>
                    )}
                  </Tab>
                );
              })}
            </Tabs>
          </div>
          <div className="flex justify-center w-full sm:col-span-3 sm:justify-end">
            <BackToTop inactiveColor={'#000000'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
