import React, { useState } from 'react';
import TabButtons from './TabButtons';

const Tabs = (props) => {
  const [state, setState] = useState({
    activeTab: props.children[0].props.label,
  });
  const changeTab = (tab) => {
    setState({ activeTab: tab });
  };
  let content;
  let buttons = [];
  return (
    <div>
      <div>
        {React.Children.map(props.children, (child) => {
          buttons.push(child.props.label);
          if (child.props.label === state.activeTab) content = child.props.children;
        })}

        <TabButtons
          activeTab={state.activeTab}
          buttons={buttons}
          changeTab={changeTab}
          setCategory={props.setCategory}
          setCurrentPage={props.setCurrentPage}
        />
        <div className="tab-content">{content}</div>
      </div>
    </div>
  );
};

export default Tabs;
